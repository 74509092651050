import { initializeApp } from "firebase/app";
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2HjqJYkQ4mBbtbS88DBjByMTfl8XV238",
  authDomain: "myvitalpath-c4f97.firebaseapp.com",
  projectId: "myvitalpath-c4f97",
  storageBucket: "myvitalpath-c4f97.firebasestorage.app",
  messagingSenderId: "931482923520",
  appId: "1:931482923520:web:3e2630a0ba189eaba45ee5",
  measurementId: "G-XGZBXCHSLF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// Register Service Worker
if (
  !Capacitor.isNativePlatform() &&
  "serviceWorker" in navigator &&
  window.innerWidth > 720
) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js", { type: "module" })
    .then((registration) => {
      console.log("Service Worker registered successfully:", registration);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BAfZYEwbqHyHeUxPTDCPsehulblloSgrismCcxoySLGNjkJzYCUBG0v1bdDlLN17WSarTszDA_R5FClFFeZ5k40",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("currentToken", currentToken);
          } else {
            console.error(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.error("An error occurred while retrieving token. ", err);
        });
    }
  });
}
if (!Capacitor.isNativePlatform() && window.innerWidth > 720) {
  requestPermission();
}

// Initialize Auth with platform-specific persistence for native platforms
export const auth = Capacitor.isNativePlatform()
  ? initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    })
  : getAuth(app);

export const db = getFirestore(app);
