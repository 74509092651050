import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { FunctionComponent } from "react";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";
import { useParams } from "react-router-dom";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  user: any;
}
export const AddRoom: FunctionComponent<SelectDialogProps> = (props) => {
  let { facilityId } = useParams();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [room, setRoom] = useState({
    name: "",
    capacity: "",
    roomNumber: "",
    description: "",
    facilityId: facilityId,
  });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setRoom({ ...room, [name]: value });
  };
  const handleSubmit = () => {
    try {
      axios.post(`${urlServer}/api/room`, room);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="p-4"
      open={props.open}
      handler={props.onClose}
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="justify-between pb-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="mb-1 font-bold"
        >
          Add Room
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={props.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="overflow-y-scroll pt-0"
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Name
            </Typography>
            <Input
              name="name"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="Room Name"
              labelProps={{
                className: "hidden",
              }}
              value={room.name}
              onChange={handleChange}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            />
          </div>
          <div className="col-span-12">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Number
            </Typography>
            <Input
              name="roomNumber"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="Room Number"
              labelProps={{
                className: "hidden",
              }}
              value={room.roomNumber}
              onChange={handleChange}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            />
          </div>
        </div>
        <div className="mt-4">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Description
          </Typography>
          <Textarea
            name="description"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            rows={6}
            placeholder="Description"
            labelProps={{
              className: "hidden",
            }}
            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            value={room.description}
            onChange={handleChange}
          />
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="gap-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={() => {
            handleSubmit();
            props.onClose();
          }}
        >
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AddRoom;
