import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { IconButton } from "@material-tailwind/react";
import {
  PhotoIcon,
  FaceSmileIcon,
  CameraIcon,
  DocumentTextIcon,
  UserCircleIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/solid";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../Configuration/firebase";

const Actions = [
  {
    color: "#4da5fe",
    icon: <PhotoIcon className="h-6 w-6" />,
    y: 102,
    title: "Photo/Video",
  },
  {
    color: "#1b8cfe",
    icon: <FaceSmileIcon className="h-6 w-6" />,
    y: 172,
    title: "Stickers",
  },
  {
    color: "#0172e4",
    icon: <CameraIcon className="h-6 w-6" />,
    y: 242,
    title: "Image",
  },
  {
    color: "#0159b2",
    icon: <DocumentTextIcon className="h-6 w-6" />,
    y: 312,
    title: "Document",
  },
  {
    color: "#013f7f",
    icon: <UserCircleIcon className="h-6 w-6" />,
    y: 382,
    title: "Contact",
  },
];

interface ChatInputProps {
  value: string;
  emojiRef: RefObject<HTMLButtonElement>;
  onOpenPicker: () => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (event: any) => void;
}

const ChatInput: React.FunctionComponent<ChatInputProps> = (props) => {
  const [openAction, setOpenAction] = useState(false);

  return (
    <div className="relative w-full">
      <input
        className="w-full py-2 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:ring-blue-400"
        type="text"
        value={props.value}
        onChange={(e: any) => props.onChange(e)}
        onKeyDown={(e) => props.onKeyDown(e)}
        placeholder="Write a message..."
      />
      <div
        className={`absolute bottom-14 right-8 ${
          openAction ? "block" : "hidden"
        }`}
      >
        {Actions.map((el, index) => (
          <button
            key={index}
            className={`absolute top-${el.y} bg-${el.color} rounded-full p-2`}
            onClick={() => {}}
          >
            {el.icon}
          </button>
        ))}
      </div>
      <button
        ref={props.emojiRef}
        className="absolute bottom-2 right-2 bg-blue-500 text-white rounded-full "
        onClick={() => props.onOpenPicker()}
      >
        <FaceSmileIcon className="h-6 w-6" />
      </button>
    </div>
  );
};

export const RoomFooter = ({ room, user }: any) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const pickerRef = useRef<HTMLDivElement>(null);
  const emojiRef = useRef<HTMLButtonElement>(null);

  const handleSendMessage = async () => {
    if (newMessage.trim() && user) {
      await addDoc(collection(db, "chatRooms", room.id, "messages"), {
        text: newMessage,
        createdAt: serverTimestamp(),
        senderId: user._id,
        type: "text",
      });
      setNewMessage("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node) &&
        emojiRef.current &&
        !emojiRef.current.contains(event.target as Node)
      ) {
        setOpenPicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef, emojiRef]);

  return (
    <div className="p-2 w-full bg-white shadow-md relative">
      <div className="flex items-center justify-between">
        <div className="w-full">
          <div
            className={`absolute ${
              openPicker ? "block" : "hidden"
            } z-10 fixed bottom-16 right-12`}
            ref={pickerRef}
          >
            <Picker
              theme={"light"}
              data={data}
              onEmojiSelect={handleEmojiSelect}
            />
          </div>
          <ChatInput
            value={newMessage}
            emojiRef={emojiRef}
            onOpenPicker={() => setOpenPicker((prev: boolean) => !prev)}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </div>
        <div className="h-12 w-12 rounded-lg flex items-center justify-center">
          <IconButton
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            onClick={() => handleSendMessage()}
          >
            <PaperAirplaneIcon className="text-white h-6 w-6" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
