import React, { useState } from "react";
import "./Greeting.scss";

const Greeting: React.FC<{ name: string }> = ({ name }) => {
  const currentHour = new Date().getHours();
  const greeting = currentHour < 12 ? "Good morning" : "Good afternoon";
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  return (
    <h1 className="greeting text-3xl font-bold mt-2 mb-4 text-gray-800">
      {`${greeting}, ${user.firstName} 👋`}
    </h1>
  );
};

export default Greeting;
