import { Outlet } from "react-router";
import { Navbar } from "../../../Components/Navbar";
import { Footer } from "../../../Components/Footer";
import { SidebarWithLogo } from "../../../Components/Sidebar";

export const MainLayout = () => {
  return (
    <div className="flex h-screen overflow-hidden">
      <div className="hidden md:flex">
        <SidebarWithLogo />
      </div>
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navbar />
        <div className="flex-1 overflow-y-auto" style={{ background: "white" }}>
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};
