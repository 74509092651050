import { auth, messaging } from "../Configuration/firebase";
import { useEffect, useState } from "react";
import { ProfileMenu } from "./ProfileMenu";
import { BellIcon, PowerIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Select, Typography, Option } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { IUserFacility } from "../Data/user";
import { IFacility } from "../Data/facility";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";
import { IconButton, Drawer } from "@material-tailwind/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { SidebarWithLogo } from "./Sidebar";
import "./Navbar.scss";
import { onMessage } from "firebase/messaging";

export const Navbar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const [facility, setFacility] = useState<IFacility | null>(null);

  const signOut = () => {
    localStorage.clear();
    auth.signOut();
    setUser(null);
  };

  const changeFacility = async (facilityId: string) => {
    const facilityResult = await axios.get(
      `${urlServer}/api/facility/${facilityId}`
    );
    const facility = facilityResult.data;
    if (facility) {
      // Save in Cache
      localStorage.setItem("facility", JSON.stringify(facility));
      setFacility(facility);
      navigate(`/facility/${facility._id}/dashboard`);
      window.location.reload();
    }
  };

  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
      onClick: () => {
        navigate(`/facility/${facility?._id}/user/${user._id}`, {
          state: { user1: user },
        });
      },
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      onClick: () => signOut(),
    },
  ];

  const addTokenToUser = async (userId: string, token: string) => {
    try {
      await axios.put(`${urlServer}/api/user/${userId}/add-token`, {
        token,
      });
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const facilityData = localStorage.getItem("facility");
    if (userData && facilityData) {
      const user = JSON.parse(userData);
      const currentToken = localStorage.getItem("currentToken");
      if (currentToken) {
        addTokenToUser(user._id, currentToken);
      }
      const facility = JSON.parse(facilityData);
      setUser(user);
      setFacility(facility);

      // Listen for notifications when app is in foreground
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);

        // You can handle the notification here, for example, showing an alert or toast
        alert(
          `New notification: ${payload.notification?.title} - ${payload.notification?.body}`
        );
      });
      // Cleanup on unmount
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, []);

  return (
    <>
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center">
          {user && (
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="text"
              size="lg"
              onClick={toggleDrawer}
              className="md:hidden"
            >
              <Bars3Icon className="h-8 w-8" />
            </IconButton>
          )}
          <Typography
            className="facilityName"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h5"
          >
            {facility?.name || "MyVitalPath"}
          </Typography>
        </div>
        <div className="flex items-center">
          {user ? (
            <div className="flex items-center gap-2">
              {user.facilities.length > 1 && (
                <Select
                  label="Select Facility"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  value={facility?._id || ""}
                  onChange={(facilityId) => changeFacility(facilityId || "")}
                >
                  {user.facilities.map((facility: IUserFacility) => {
                    return (
                      <Option value={facility.facilityId}>
                        {facility.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <ProfileMenu
                profileMenuItems={profileMenuItems}
                notificationsPlacement={1}
                name={user ? `${user.firstName} ${user.lastName}` : ""}
                id={user._id}
              />
            </div>
          ) : (
            <button onClick={() => navigate("/login")}>Log In</button>
          )}
        </div>
      </div>
      <Drawer
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        open={isDrawerOpen}
        onClose={toggleDrawer}
        className="p-0 w-auto"
      >
        <SidebarWithLogo onCloseDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
};
