import {
  BellIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import cloneDeep from "lodash/cloneDeep";
import {
  Avatar,
  Badge,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { db } from "../Configuration/firebase";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { IUser } from "../Data/user";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

interface ProfileMenuItem {
  label: string;
  icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  nested?: boolean;
  onClick?: () => void;
}

interface ProfileMenuProps {
  id: string;
  name: string;
  notificationsPlacement?: number;
  profileMenuItems: ProfileMenuItem[];
}

function ClockIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99998 14.9C9.69736 14.9 11.3252 14.2257 12.5255 13.0255C13.7257 11.8252 14.4 10.1974 14.4 8.49998C14.4 6.80259 13.7257 5.17472 12.5255 3.97449C11.3252 2.77426 9.69736 2.09998 7.99998 2.09998C6.30259 2.09998 4.67472 2.77426 3.47449 3.97449C2.27426 5.17472 1.59998 6.80259 1.59998 8.49998C1.59998 10.1974 2.27426 11.8252 3.47449 13.0255C4.67472 14.2257 6.30259 14.9 7.99998 14.9ZM8.79998 5.29998C8.79998 5.0878 8.71569 4.88432 8.56566 4.73429C8.41563 4.58426 8.21215 4.49998 7.99998 4.49998C7.7878 4.49998 7.58432 4.58426 7.43429 4.73429C7.28426 4.88432 7.19998 5.0878 7.19998 5.29998V8.49998C7.20002 8.71213 7.28434 8.91558 7.43438 9.06558L9.69678 11.3288C9.7711 11.4031 9.85934 11.4621 9.95646 11.5023C10.0536 11.5425 10.1577 11.5632 10.2628 11.5632C10.3679 11.5632 10.472 11.5425 10.5691 11.5023C10.6662 11.4621 10.7544 11.4031 10.8288 11.3288C10.9031 11.2544 10.9621 11.1662 11.0023 11.0691C11.0425 10.972 11.0632 10.8679 11.0632 10.7628C11.0632 10.6577 11.0425 10.5536 11.0023 10.4565C10.9621 10.3593 10.9031 10.2711 10.8288 10.1968L8.79998 8.16878V5.29998Z"
        fill="#90A4AE"
      />
    </svg>
  );
}

export const ProfileMenu: FunctionComponent<ProfileMenuProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [openNestedMenu, setOpenNestedMenu] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const closeMenu = () => setIsMenuOpen(false);
  let { facilityId } = useParams();

  const getUsers = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/${facilityId}/users`);
      setUsers(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  const getTimeAgo = (time: Timestamp): string => {
    const currentUnixSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
    const diffInSeconds = currentUnixSeconds - time.seconds;

    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_HOUR = 3600;
    const SECONDS_IN_DAY = 86400;

    if (diffInSeconds < SECONDS_IN_MINUTE) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < SECONDS_IN_HOUR) {
      return `${Math.floor(diffInSeconds / SECONDS_IN_MINUTE)} minutes ago`;
    } else if (diffInSeconds < SECONDS_IN_DAY) {
      return `${Math.floor(diffInSeconds / SECONDS_IN_HOUR)} hours ago`;
    } else {
      return `${Math.floor(diffInSeconds / SECONDS_IN_DAY)} days ago`;
    }
  };

  useEffect(() => {
    // Create a query with ordering
    const q = query(
      collection(db, "notifications"),
      where("mongoDBId", "==", props.id.toString()),
      orderBy("createdAt", "desc") // Sorting by the `timestamp` field in descending order
    ); //

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      let tempUsers = cloneDeep(users);
      if (tempUsers.length === 0) {
        tempUsers = await getUsers();
      }

      // Loop through all documents in the snapshot
      const allNotifications: any[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      allNotifications.forEach((notification) => {
        notification.user = tempUsers.find(
          (user) => user._id === notification.mongoDBId
        );
      });

      // Set the notifications state with the latest data
      setNotifications(allNotifications);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <Badge
        content={
          notifications?.filter(
            (notification) => notification.status === "sent"
          ).length
        }
        overlap="circular"
        invisible={
          notifications?.filter(
            (notification) => notification.status === "sent"
          ).length === 0
        }
      >
        <MenuHandler>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 px-0.5 lg:ml-auto"
          >
            <Avatar
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="circular"
              size="sm"
              className="border border-gray-900 p-0.5"
              src={`https://ui-avatars.com/api/?name=${props.name}&background=random`}
            />
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${
                isMenuOpen ? "rotate-180" : ""
              }`}
            />
          </Button>
        </MenuHandler>
      </Badge>
      <MenuList
        className="p-1"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {props.profileMenuItems.map((item, key) => {
          const isLastItem = key === props.profileMenuItems.length - 1;

          return (
            <React.Fragment key={"Notifications"}>
              {/* Render notification menu for the specific placement */}
              {props.notificationsPlacement === key && (
                <Menu
                  placement="right-start"
                  open={openNestedMenu}
                  handler={setOpenNestedMenu}
                  allowHover
                  offset={15}
                >
                  <MenuHandler className="flex items-center justify-between">
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      <div className="flex item-center gap-1">
                        {React.createElement(BellIcon, {
                          className: `h-4 w-4`,
                          strokeWidth: 2,
                        })}
                        Notifications
                        <Badge
                          content=""
                          overlap="circular"
                          invisible={
                            notifications?.filter(
                              (notification) => notification.status === "sent"
                            ).length === 0
                          }
                        />
                      </div>
                      <ChevronUpIcon
                        strokeWidth={2.5}
                        className={`h-3.5 w-3.5 transition-transform ${
                          openNestedMenu ? "rotate-90" : ""
                        }`}
                      />
                    </MenuItem>
                  </MenuHandler>
                  <MenuList
                    className="flex flex-col gap-2"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    {notifications.map((notification) => (
                      <MenuItem
                        className="flex items-center gap-4 py-2 pl-2 pr-8"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        <Avatar
                          variant="circular"
                          alt="tania andrew"
                          src={`https://ui-avatars.com/api/?name=${
                            notification.user.firstName +
                            " " +
                            notification.user.lastName
                          }&background=random`}
                          size="sm"
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          placeholder={undefined}
                        />
                        <div className="flex flex-col gap-1">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-semibold"
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                          >
                            {notification.user.firstName} send you a message
                          </Typography>
                          <Typography
                            className="flex items-center gap-1 text-sm font-medium text-blue-gray-500"
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                          >
                            <ClockIcon />
                            {getTimeAgo(notification.createdAt)}
                          </Typography>
                        </div>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}

              {/* Render the regular menu item */}
              <MenuItem
                onClick={() => {
                  if (item.onClick) item.onClick();
                  closeMenu();
                }}
                className={`flex items-center gap-2 rounded ${
                  isLastItem
                    ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                    : ""
                }`}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {React.createElement(item.icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? "red" : "inherit"}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  {item.label}
                </Typography>
              </MenuItem>
            </React.Fragment>
          );
        })}
      </MenuList>
    </Menu>
  );
};
